import {
  AllUser,
  AllUserActive,
  Teleconsult,
  TeleconsultInactive,
  Clinic,
  ClinicInactive,
  HealthScreening,
  HealthScreeningInactive,
  TeleFit,
  TeleFitInactive,
  MincAdmin,
  MincAdminInactive,
  TeleCounselInactive,
  TeleCounsel,
} from '@/assets/svg';
import { urlLabel } from '@/enum/PermissionEnum';

export const renderGroupMenu = () => [
  {
    title: 'All Users',
    privilege: 'HomescreenAllUsers',
    value: urlLabel.userDetails,
    path: urlLabel.userDetails,
    isItem: true,
    iconInActive: AllUser,
    iconActive: AllUserActive,
    children: [
      {
        value: urlLabel.editUserDetail,
      },
    ],
  },

  {
    title: 'Teleconsult',
    isGroupItem: true,
    isOpen: false,
    value: 'Teleconsult',
    iconInActive: TeleconsultInactive,
    iconActive: Teleconsult,
    children: [
      {
        title: 'Queue',
        value: urlLabel.waitingRoom,
        privilege: 'ConsultRoom',
      },
      {
        title: 'Appointments',
        privilege: 'Appointments',
        value: urlLabel.appointmentManagement,
      },
      {
        title: 'All teleconsults (CE)',
        value: urlLabel.consultList,
        privilege: 'VisitRecords',
      },
      {
        title: 'All teleconsults (Dr)',
        value: urlLabel.meetingHistory,
        privilege: 'ConsultList',
      },
      {
        title: 'Patient Details',
        value: urlLabel.patientManagement,
        privilege: 'PatientManagement',
      },
      {
        title: 'Setup',
        privilege: 'TeleconsultSetup',
        value: 'Teleconsult Set Up',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Appointments',
            privilege: 'AppointmentConfig',
            value: urlLabel.bookingManagement,
          },
          {
            title: 'Doctors',
            value: urlLabel.doctorManagement,
            privilege: 'DoctorManagement',
          },
          {
            title: 'CE',
            value: urlLabel.caManagement,
            privilege: 'CAManagement',
          },
          {
            title: 'Promo Code',
            privilege: 'PromoCode',
            value: urlLabel.promotion,
          },
        ],
      },

      {
        title: 'Analytics',
        privilege: 'TeleconsultAnalytics',
        value: urlLabel.teleconsultAnalytics,
      },

      // {
      //   title: 'Patient Management',
      //   value: urlLabel.patientManagement,
      //   privilege: 'PatientManagement',
      // },

      // {
      //   title: 'MHS Management',
      //   privilege: 'MHSManagement',
      //   value: urlLabel.mhsManagement,
      // },
    ],
  },
  {
    title: 'Clinic',
    isOpen: false,
    isGroupItem: true,
    value: 'Clinic',
    iconInActive: ClinicInactive,
    iconActive: Clinic,
    children: [
      {
        title: 'Queue Requests',
        privilege: 'QueueRequest',
        value: urlLabel.queueManagement,
      },
      {
        title: 'Appointments',
        privilege: 'ClinicAppointments',
        value: urlLabel.clinicAppointments,
      },
      {
        title: 'Setup',
        privilege: 'ClinicSetUpMenu',
        value: 'Clinic SetUp',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Locations',
            privilege: 'ClinicSetUp',
            value: urlLabel.clinicManagement,
          },
          {
            title: 'Services',
            privilege: 'ClinicServices',
            value: urlLabel.clinicServices,
          },
        ],
      },
    ],
  },
  {
    title: 'Health Screening',
    value: 'TeleScreen',
    isGroupItem: true,
    isOpen: false,
    iconInActive: HealthScreeningInactive,
    iconActive: HealthScreening,
    children: [
      {
        title: 'Appointments Today',
        privilege: 'Upcoming',
        value: urlLabel.upcomingHealthScreening,
        children: [
          {
            value: urlLabel.upcomingEditAppointment,
          },
        ],
      },
      {
        title: 'All Screenings',
        privilege: 'HealthScreeningAppointments',
        value: urlLabel.allScreenings,
        children: [
          { value: urlLabel.aSEditAppointment },
          { value: urlLabel.aSviewPatientRegistration },
          { value: urlLabel.aSRegisterPatient },
          { value: urlLabel.aSEditPackage },
          { value: urlLabel.aSCreateAppointment },
          { value: urlLabel.aSScreeningProgress },
          { value: urlLabel.aSPatientRegistration },
          { value: urlLabel.aSCreateFollowUpAppointment },
          { value: urlLabel.aSRescheduleAppointment },
        ],
      },
      {
        title: 'Onsite Ops',
        privilege: 'OnsiteOps',
        value: 'Onsite Ops',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Lab E-order',
            privilege: 'EOrder',
            value: urlLabel.orderListExport,
          },
          {
            title: 'Closing Report',
            privilege: 'ClosingReport',
            value: urlLabel.siteClosingReport,
          },
        ],
      },
      {
        title: 'Backroom',
        privilege: 'Backroom',
        value: urlLabel.backroomModule,
      },
      {
        title: 'Corporate Reports',
        privilege: 'CorporateReport',
        value: urlLabel.corporateReport,
      },
      // {
      //   title: 'Queue',
      //   privilege: 'Queue',
      //   value: urlLabel.screenQueue,
      // },

      {
        title: 'Setup',
        privilege: 'Setup',
        value: 'Set Up',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Company',
            privilege: 'CompanyManagement',
            value: urlLabel.companyManagement,
            children: [
              { value: urlLabel.createCompany },
              { value: urlLabel.editCompany },
              { value: urlLabel.createProject },
              { value: urlLabel.editProject },
              { value: urlLabel.projectManage },
            ],
          },
          {
            title: 'Locations',
            privilege: 'SiteConfig',
            value: urlLabel.locationManagement,
            children: [
              { value: urlLabel.locationManagementCreate },
              { value: urlLabel.locationManagementEdit },
            ],
          },

          {
            title: 'Screening Packages',
            privilege: 'Packages',
            value: urlLabel.configPackages,
          },
          {
            title: 'Test Profiles',
            privilege: 'Profiles',
            value: urlLabel.configProfiles,
          },

          {
            title: 'Other Services',
            privilege: 'OtherServices',
            value: urlLabel.configOtherServices,
          },

          {
            title: 'Lifestyle Survey',
            privilege: 'Survey',
            value: urlLabel.configLifestyleSurvey,
          },
          {
            title: 'Consent Form',
            privilege: 'ConsentForm',
            value: urlLabel.configConsentForm,
          },

          {
            title: 'Import HS Appointments',
            privilege: 'ImportHSAppointments',
            value: urlLabel.importHSAppointments,
          },
          {
            title: 'Stations',
            privilege: 'StationManagement',
            value: urlLabel.configStations,
          },
        ],
      },

      {
        title: 'Admin',
        privilege: 'HealthScreeningAdmin',
        value: 'Health ScreeningAdmin',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Timeslot Template',
            privilege: 'TimeslotsTemplate',
            value: urlLabel.configTimeslotTemplate,
          },
          {
            title: 'Motherhood Statement',
            privilege: 'MotherhoodStatements',
            value: urlLabel.configMotherhood,
          },
        ],
      },
    ],
  },
  {
    title: 'Counselling',
    isGroupItem: true,
    isOpen: false,
    value: 'Counselling',
    iconInActive: TeleCounselInactive,
    iconActive: TeleCounsel,
    children: [
      {
        title: 'Queue',
        value: urlLabel.telecounselQueue,
        privilege: 'CounselQueue',
      },
      {
        title: 'Appointments',
        value: urlLabel.telecounselAppointments,
        privilege: 'CounselAppointments',
      },
      {
        privilege: 'CounselAll',
        value: urlLabel.telecounselAllCounsels,
        title: 'All counsellings',
      },

      {
        title: 'Setup',
        privilege: 'ResourcesManagement',
        value: 'Resources Management',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Appointments',
            privilege: 'CounsellorConfig',
            value: urlLabel.telecounselAppointmentConfig,
          },
          {
            title: 'Counsellors',
            privilege: 'CounsellorManagement',
            value: urlLabel.telecounseRMCounsellors,
          },
          {
            title: 'CE',
            privilege: 'CounsellorExecutivesManagement',
            value: urlLabel.telecounseRMCounsellorExecutives,
          },
        ],
      },
    ],
  },

  // {
  //   title: 'TeleFit',
  //   isOpen: false,
  //   isGroupItem: true,
  //   value: 'TeleFit',
  //   iconInActive: TeleFitInactive,
  //   iconActive: TeleFit,
  //   children: [
  //     {
  //       title: 'Workout',
  //       value: urlLabel.categoryManagement,
  //       privilege: 'Workout',
  //       isOpen: false,
  //       hasSubmenu: true,
  //       children: [
  //         {
  //           title: 'Categories',
  //           value: urlLabel.categoryManagement,
  //           privilege: 'Categories',
  //         },
  //         {
  //           title: 'Livestream',
  //           value: urlLabel.livestreamManagement,
  //           privilege: 'Livestream',
  //           children: [
  //             {
  //               value: urlLabel.livestreamSectionDetail,
  //             },
  //           ],
  //         },
  //         {
  //           title: 'On Demand',
  //           value: urlLabel.onDemandManagement,
  //           privilege: 'OnDemand',
  //         },
  //         {
  //           title: 'Studio Classes',
  //           value: urlLabel.studioClassesManagement,
  //           privilege: 'StudioClasses',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Upcoming Workouts',
  //       privilege: 'UpcomingWorkout',
  //       value: urlLabel.upcomingWorkoutManagement,
  //     },
  //     {
  //       title: 'Studio Management',
  //       privilege: 'StudioManagement',
  //       value: urlLabel.studioManagement,
  //     },
  //     {
  //       title: 'Trainer Management',
  //       privilege: 'TrainerManagement',
  //       value: urlLabel.trainerManagement,
  //     },
  //     {
  //       title: 'Internal Trainer Management',
  //       privilege: 'InternalTrainerManagement',
  //       value: urlLabel.userManagement,
  //     },
  //   ],
  // },
  {
    title: 'Support',
    value: 'MincAdmin',
    isGroupItem: true,
    isOpen: false,
    iconInActive: MincAdminInactive,
    iconActive: MincAdmin,
    children: [
      {
        title: 'Admin',
        privilege: 'Admin',
        value: 'Admin',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Users',
            privilege: 'UserManagement',
            value: urlLabel.adminManagement,
          },
          {
            title: 'Roles',
            privilege: 'RoleManagement',
            value: urlLabel.rolesManagement,
          },
        ],
      },
      {
        title: 'Customer Care Support',
        privilege: 'CustomerCareSupport',
        value: 'Customer Care Support',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Account Support',
            privilege: 'APISupport',
            value: urlLabel.apiSupport,
            isSupport: true,
          },
          {
            title: 'Restricted Accounts',
            privilege: 'StuckAccounts',
            value: urlLabel.stuckAccounts,
          },
        ],
      },

      {
        title: 'E-commerce',
        privilege: 'EcommerceManagement',
        value: urlLabel.ecommerceManagement,
      },

      {
        title: 'Marketing',
        privilege: 'Marketing',
        value: 'Marketing',
        isOpen: false,
        hasSubmenu: true,
        children: [
          {
            title: 'Banners',
            privilege: 'BannerManagement',
            value: urlLabel.bannerManagement,
          },
          {
            title: 'App Notifications',
            privilege: 'MarketingMessage',
            value: urlLabel.marketingMessages,
          },
        ],
      },

      // {
      //   title: 'Deletion Worklist',
      //   privilege: 'DeletionWorklist',
      //   value: urlLabel.deletionRequest,
      // },
      // {
      //   title: 'Lab Result',
      //   privilege: 'LabResult',
      //   value: urlLabel.labResult,
      // },
    ],
  },
];
