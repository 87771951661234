import { REQUIRED_FIELD } from './constant';
import { FIELD_ARRAY_VALUE } from '@/module/company/constants';
import { DEFAULT_COMPULSORY_TAGS } from '@/module/configuration/constants';
import { TYPE_MODAL } from '@/module/livestream/constants';
import { LAB_PROVIDERS } from '@/new-components/CustomModal/ModalAssignLabNumber';
import {
  validateIdentity,
  validateNRIC,
  validateMobile,
} from '@/utils/validation';
import { get, set, forEach, isEmpty, every, isObject } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

const DECIMAL_REGEX = /^[1-9]\d*(.\d+)?$/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/;
const FULL_NAME_REGEX =
  /^(?=.*[a-zA-Z])(?!\d+$)(?:[a-zA-Z !@#$&()`/.+,:;']*)?$/;
const COMBINE_TEXT_AND_NUMER_REGEX =
  /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
export const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9]*$/;
export const ONLY_NUMBER_REGEX = /^[0-9\b]+$/;
export const SPECIAL_CHARACTERS_REGEX =
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
export const NRIC_REGEX = /^[STFGMstfgm]\d{7}[a-zA-Z]$/;
export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*(),_\-+=]{8,32}$/;

const serviceSchema = Yup.object().shape({
  name: Yup.string().trim().required('Test Name is required'),
  labHeader: Yup.string()
    .nullable()
    .test({
      name: 'validator-custom-service',
      test: function (value) {
        const requireLabHeader = this.resolve(Yup.ref('requireLabHeader'));
        const param = this.resolve(Yup.ref('param')) || 'Lab Code';
        if (requireLabHeader) {
          if (!value)
            return this.createError({
              message: `${param} is required`,
              path: 'labHeader',
            });
          // else if (value.length > 8)
          //   return this.createError({
          //     message: `The maximum length of ${param} is 8 characters`,
          //     path: 'labHeader'
          //   });
        }
        return true;
      },
    }),
  loinc: Yup.string()
    .nullable()
    .test({
      name: 'validator-custom-service',
      test: function (value) {
        const requireLoinc = this.resolve(Yup.ref('requireLoinc'));
        if (requireLoinc) {
          if (!value)
            return this.createError({
              message: `Loinc is required`,
              path: 'loinc',
            });
        }
        return true;
      },
    }),
  euroFinLOINC: Yup.string()
    .nullable()
    .test({
      name: 'validator-custom-service',
      test: function (value) {
        const requireLoinc = this.resolve(Yup.ref('requireLoinc'));
        if (requireLoinc) {
          if (!value)
            return this.createError({
              message: `Eurofin Loinc is required`,
              path: 'euroFinLOINC',
            });
        }
        return true;
      },
    }),
  tags: Yup.array()
    .nullable()
    .test(
      '',
      `Please select one of these options (${DEFAULT_COMPULSORY_TAGS.join(
        ', '
      )})`,
      function (value) {
        const filterList = value.filter((it) =>
          DEFAULT_COMPULSORY_TAGS.some((spec) => it.includes(spec))
        );
        if (isEmpty(filterList)) return false;
        return true;
      }
    ),

  // .required('Loinc Code is required')
});

const companySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  staffQuantity: Yup.string().required('Quantity is required'),
  // unit: Yup.string().required('Unit is required')
});

const locationSchema = Yup.object().shape({
  unit: Yup.string().required('Unit is required'),
  address: Yup.string().required('Address is required'),
  postalCode: Yup.string()
    .nullable()
    .min(6, 'Postal Code must be 6 digits')
    .test('', 'Postal Code is required', function (value) {
      const address1 = this.resolve(Yup.ref('address1'));
      if (!isEmpty(address1) && isEmpty(value)) return false;
      return true;
    }),
  name: Yup.string().required('Location Name is required').nullable(),
  dayOfWeeks: Yup.array().test(
    'Must not be empty',
    'Time slots are required',
    function (value) {
      const isEmptyVal = every(value, (val) => isEmpty(val.timeSlots));
      if (isEmptyVal) {
        return false;
      }
      return true;
    }
  ),
});
const healthProfileSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  code: Yup.string().trim().required('Code is required'),
  price: Yup.number()
    .max(999999999, 'The price value is too high. Please enter again')
    .nullable()
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  tags: Yup.array()
    .nullable()
    .test(
      '',
      `Please select one of these options (${DEFAULT_COMPULSORY_TAGS.join(
        ', '
      )})`,
      function (value) {
        const filterList = value.filter((it) =>
          DEFAULT_COMPULSORY_TAGS.some((spec) => it.includes(spec))
        );
        if (isEmpty(filterList)) return false;
        return true;
      }
    ),
});

const packageSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  code: Yup.string().trim().required('Code is required'),
  packageType: Yup.string().trim().required('Package Type is required'),
  price: Yup.number()
    .max(999999999, 'The price is too high. Please enter again')
    .nullable()
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  tags: Yup.array()
    .nullable()
    .test(
      '',
      `Please select one of these options (${DEFAULT_COMPULSORY_TAGS.join(
        ', '
      )})`,
      function (value) {
        const filterList = value.filter((it) =>
          DEFAULT_COMPULSORY_TAGS.some((spec) => it.includes(spec))
        );
        if (isEmpty(filterList)) return false;
        return true;
      }
    ),
});

const registerAccount = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .matches(EMAIL_REGEX, 'Email is invalid'),
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Confirm password does not match'),
});

const registerPersonal = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  lstCertificates: Yup.string().required('Certificate is required'),
  // mobile: Yup.string().required('Phone number is required')
});

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGEX, 'Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
    )
    .required('Password is required'),
});

const studioSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  unit: Yup.string().required('Unit is required'),
  imageUrls: Yup.array().required('Image is required'),
});

const trainerSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password2: Yup.string().oneOf(
    [Yup.ref('password')],
    "Password and Confirm Password doesn't match"
  ),
  praticisingCertificate: Yup.mixed().required('Certificate is required'),
});

const userSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
    )
    .required('Password is required'),
  password2: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], "Confirm Password doesn't match"),
});

const userSchemaEdit = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password2: Yup.string().oneOf(
    [Yup.ref('password')],
    "Password and Confirm Password doesn't match"
  ),
});

const promotionSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  code: Yup.string().required('Code is required'),
  description: Yup.string().required('Description is required'),
  startDate: Yup.date()
    .nullable()
    .required('Start date is required')
    .min(moment().startOf('day'), 'Start date must be later than current date'),
  endDate: Yup.date()
    .nullable()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be later than start date'),
  quota: Yup.string()
    .test('not empty', 'Quota is required', (value) => {
      return !!value;
    })
    .test('Must a number and required', 'Quota must be a number', (value) => {
      if (!value) return true;
      return value.match(/^\d+$/);
    }),
});

const categorySchema = Yup.object().shape({
  thumbnail: Yup.string().required('Thumbnail is required'),
  title: Yup.string().required('Category name is required'),
  intensity: Yup.string().required('Intensity is required'),
  calories: Yup.string().required('Calories is required'),
});

const appointmentSchema = Yup.object().shape({
  appointmentType: Yup.string().required('Appointment Type is required'),
  appointmentDate: Yup.string().required('Appointment Date is required'),
  appointmentTime: Yup.string()
    .nullable()
    .required('Appointment Time is required')
    .test(
      'Must be greater than current time',
      'Appointment time must be later than current time',
      (value) =>
        value ? new Date(value).getTime() > new Date().getTime() : true
    ),
  // doctorId: Yup.string()
  //   .nullable()
  //   .test(
  //     'Must be not empty when assign doctor',
  //     'Doctor is required',
  //     function(value) {
  //       let typeModal = this.resolve(Yup.ref('typeModal'));
  //       if (['AssignDoctor', 'Create'].includes(typeModal) && isEmpty(value)) {
  //         return false;
  //       }
  //       return true;
  //     }
  //   ),
  patient: Yup.object()
    .test(
      'Must be a non-empty object when creating',
      'Patient is required',
      function (value) {
        let typeModal = this.resolve(Yup.ref('typeModal'));
        if (typeModal === 'Create' && isEmpty(value)) {
          return false;
        }
        return true;
      }
    )
    .nullable(),
});
const mhsSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // mobile: Yup.string()
  //   .required('Phone number is required')
  //   .matches(PHONE_REGEX, 'Phone number is invalid')
  //   .test('testMobile', 'Phone number is invalid', function (value) {
  //     const countryCode = this.resolve(Yup.ref('countryCode'));
  //     return validateMobile(value, countryCode);
  //   }),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    "Password and Confirm Password doesn't match"
  ),
});
const adminSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm password does not match')
    .required('Confirm password is required'),
  countryCode: Yup.string().nullable().required('Country code is required'),
  roleType: Yup.string().required('Role Type is required'),
  // mobile: Yup.string()
  //   .nullable()
  //   .required('Phone number is required')
  //   .test('testMobile', 'Phone number is invalid', function (value) {
  //     const countryCode = this.resolve(Yup.ref('countryCode'));
  //     return validateMobile(value, countryCode);
  //   }),
  mcr: Yup.string()
    .nullable()
    .test('', 'MCR is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.mcr.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  clinicId: Yup.string()
    .nullable()
    .test('', 'Clinic is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.clinicId.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  praticisingCertificate: Yup.string()
    .nullable()
    .test('', 'Certificate is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (
        REQUIRED_FIELD.praticisingCertificate.includes(roleType) &&
        isEmpty(value)
      ) {
        return false;
      }
      return true;
    }),
  lstClinicId: Yup.string()
    .nullable()
    .test('', 'Clinics are required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.lstClinicId.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  lstCertificates: Yup.string()
    .nullable()
    .test('', 'Certificates are required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.lstCertificates.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
});
const adminEditSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  countryCode: Yup.string().nullable().required('Country code is required'),
  roleType: Yup.string().required('Role Type is required'),
  // mobile: Yup.string()
  //   .nullable()
  //   .required('Phone number is required')
  //   .test('testMobile', 'Phone number is invalid', function (value) {
  //     const countryCode = this.resolve(Yup.ref('countryCode'));
  //     return validateMobile(value, countryCode);
  //   }),
  mcr: Yup.string()
    .nullable()
    .test('', 'MCR is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.mcr.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  clinicId: Yup.string()
    .nullable()
    .test('', 'Clinic is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.clinicId.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  praticisingCertificate: Yup.string()
    .nullable()
    .test('', 'Certificate is required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (
        REQUIRED_FIELD.praticisingCertificate.includes(roleType) &&
        isEmpty(value)
      ) {
        return false;
      }
      return true;
    }),
  lstClinicId: Yup.string()
    .nullable()
    .test('', 'Clinics are required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.lstClinicId.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  lstCertificates: Yup.string()
    .nullable()
    .test('', 'Certificates are required', function (value) {
      const roleType = this.resolve(Yup.ref('roleType'));
      if (REQUIRED_FIELD.lstCertificates.includes(roleType) && isEmpty(value)) {
        return false;
      }
      return true;
    }),
});
const bannerSchema = Yup.object().shape({
  url: Yup.string().required('Banner is required'),
  order: Yup.number('Order must be a number')
    .typeError('Order must be a number')
    .required('Order is required')
    .min(0)
    .max(Yup.ref('totalItem')),
  name: Yup.string()
    .test('empty', 'URL is required', (value) => Boolean(value?.trim()))
    .required('URL is required'),
  startDate: Yup.date()
    .nullable()
    .typeError('Start date is invalid')
    .min(moment(new Date()).startOf('date'), 'Start date is invalid')
    .required('Start date is required'),
  endDate: Yup.date()
    .nullable()
    .typeError('End date is invalid')
    .when('startDate', (startDate) => {
      if (!moment(startDate).isValid()) return;

      return Yup.date()
        .min(startDate, 'End date must be later than start date')
        .required('End date is required')
        .typeError('End date is invalid');
    })
    .required('End date is required'),
});

const validationEditBooking = Yup.object().shape({
  daysOfWeek: Yup.array().required('Days of week is required'),
  startDate: Yup.date().nullable().required('Start date is required'),
  endDate: Yup.date()
    .nullable()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be later than start date'),
  startTime: Yup.date().nullable().required('Start date is required'),
  endTime: Yup.date()
    .nullable()
    .required('End time is required')
    .min(Yup.ref('startTime'), 'End time must be later than start time'),
  option: Yup.string().nullable().required('Please select one option'),
  baseSlot: Yup.string()
    .required('Number of slots is required')
    .test(
      'baseSlot test number',
      'Number of slots must be greater than 0',
      (value) => (value ? Number(value) > 0 : true)
    )
    .test(
      'baseSlot max number',
      'Number of slots must be lower than 10000',
      (value) => (value ? Number(value) < 10000 : true)
    ),
});

const validationEditBookingItem = Yup.object().shape({
  option: Yup.string().nullable().required(' Select mode must be required'),
  baseSlot: Yup.string()
    .required('Number of slots is required')
    .test(
      'baseSlot test number',
      'Number of slots must be greater than 0',
      (value) => (value ? Number(value) > 0 : true)
    )
    .test(
      'baseSlot max number',
      'Number of slots must be lower than 10000',
      (value) => (value ? Number(value) < 10000 : true)
    ),
});

const basicModalForm = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  categoryId: Yup.string().required('Workout Type is required'),
  intensity: Yup.string().required('Intensity is required'),
  duration: Yup.string().required('Duration is required'),
  calories: Yup.string().required('Calories is required'),
  description: Yup.string().nullable().required('Description is required'),
  listDates: Yup.array()
    .nullable()
    .test('', 'Start Date is required', function (value) {
      const typeModal = this.resolve(Yup.ref('typeModal'));
      if (typeModal === TYPE_MODAL.Create && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  startHour: Yup.string()
    .nullable()
    .test('', 'Start Hour is required', function (value) {
      const typeModal = this.resolve(Yup.ref('typeModal'));
      if (typeModal === TYPE_MODAL.Create && isEmpty(value)) {
        return false;
      }
      return true;
    }),
  date: Yup.string()
    .nullable()
    .test('', 'Start Date Time is required', function (value) {
      const typeModal = this.resolve(Yup.ref('typeModal'));
      if (typeModal === TYPE_MODAL.Edit && isEmpty(value)) {
        return false;
      }
      return true;
    }),
});

const projectSchema = Yup.object().shape({
  name: Yup.string().required('Project Name is required'),
  endDate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .required('End Date is required'),
  lstHealthScreenings: Yup.string().required('Missing Screening Dates'),
  startDate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .required('Start Date is required'),
  labProvider: Yup.string().required('Lab Provider is required'),
  // pmCode: Yup.string().required('Project Organization is required')
  // staffTypeLabel: Yup.string()
  //   .nullable()
  //   .test('staff type label', 'Label is required', function(value) {
  //     const lstStaffTypeItems = this.resolve(Yup.ref('lstStaffTypeItems'));
  //     if (!isEmpty(lstStaffTypeItems) && !value) {
  //       return false;
  //     }
  //     return true;
  //   })
});

const staffTypeSchema = Yup.object().shape({
  type: Yup.string().required('Value is required'),
  subsidy: Yup.string()
    .required('Sub-value is required')
    .test('', 'Invalid Sub-value', function (value) {
      const staffValueType = this.resolve(Yup.ref('staffValueType'));
      const subsidy = Number(value);
      if (value < 0) return false;
      if (staffValueType === 'Percent' && subsidy > 100) {
        return false;
      }
      return true;
    }),
});

const projectCategorySchema = Yup.object().shape({
  label: Yup.string().required('Question title is required'),
  customFieldType: Yup.string().required('Type is required'),
  value: Yup.array()
    .nullable()
    .test('', 'This type must have at least one value', function (value) {
      const type = this.resolve(Yup.ref('customFieldType'));
      if (FIELD_ARRAY_VALUE.includes(type) && isEmpty(value)) {
        return false;
      }
      return true;
    })
    .test('', 'Please enter the answer', function (value) {
      const type = this.resolve(Yup.ref('customFieldType'));
      if (FIELD_ARRAY_VALUE.includes(type)) {
        return !value.some((it) => isEmpty(it));
      }
      return true;
    }),
});

const consultListPaymentStatus = Yup.object().shape({
  remark: Yup.string().required('Remarks is required'),
});

const statementSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  statement: Yup.string().required('Statement is required'),
});

const motherStatementSchema = Yup.object().shape({
  status: Yup.string().required('Code is required'),
  expressions: Yup.array()
    .required('Statements must have at least one expression')
    .defined('Statements must have at least one expression')
    .test('', 'Please fill out all the required fields', function (value) {
      let jsonValue = JSON.stringify(value);
      const isInvalid = jsonValue.includes(`"isValid":false`);
      return !isInvalid;
    }),
  type: Yup.string().test('', 'Category is required', function (value) {
    // Check with old data
    return value !== '0';
  }),
});
const personalInformationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Full Name is required')
    .matches(
      FULL_NAME_REGEX,
      'Full Name must contain letters only or letters combined with special characters'
    ),
  nric: Yup.string()
    .nullable()
    .required('NRIC/Passport is required')
    .test('testNRIC', 'NRIC is invalid', function (value) {
      const type = this.resolve(Yup.ref('identityType'));
      if (type === 'NRIC') return validateNRIC(value);
      return true;
    })
    .test('testPassport', 'Passport is invalid', function (value) {
      const type = this.resolve(Yup.ref('identityType'));
      if (type === 'Passport') return validateIdentity(value);
      return true;
    }),
  dateOfBirth: Yup.string().nullable().required('Date of birth is required'),
  gender: Yup.string().required('Gender is required'),
  email: Yup.string()
    .nullable()
    .required('Email is required')
    .matches(EMAIL_REGEX, 'Email is invalid'),
  identityType: Yup.string().required('Type of ID is required'),
  lstIdentityUrl: Yup.array()
    .nullable()
    .test('', 'Two image of ID are required', function (value) {
      const hasData =
        value &&
        value.filter((item) => !isEmpty(item) || typeof item === 'object');
      // If have any data with type obj => Check if  don't have enough two images and contain undefined data
      if (!isEmpty(hasData) && get(hasData, 'length', 0) !== 2) return false;
      return true;
    }),
  mobile: Yup.string()
    .nullable()
    .required('Phone number is required')
    .test('testMobile', 'Phone number is invalid', function (value) {
      const countryCode = this.resolve(Yup.ref('countryCode'));
      return validateMobile(value, countryCode);
    }),
});

const stationSchema = Yup.object().shape({
  name: Yup.string().required('Station name is required'),
  shortName: Yup.string().required('Station short name is required'),
});

const timeSlotTemplate = Yup.object().shape({
  name: Yup.string().required('Template Name is required'),
  interval: Yup.string().required('Template Interval is required'),
});

const consentSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  htmlContent: Yup.string().required('Content is required'),
});

const assignConsentForm = Yup.object().shape({
  // paxConsents: Yup.array().required('Consent form must have at least one value')
});

const surveySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  questions: Yup.array().required('Categories must have at least one value'),
});

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please input your email!')
    .email('Your email is not valid'),
});

const questionnairesSchema = Yup.object().shape({
  question: Yup.string().trim().required('Question is required'),
  type: Yup.string(),
  answers: Yup.array().test(
    '',
    'Please fill out all the required fields',
    function (value) {
      let type = this.resolve(Yup.ref('type'));
      if (type === 'FreeText') return true;

      let isInvalid = isEmpty(value) || value.some((i) => !i.answer);
      return !isInvalid;
    }
  ),
});

const testBMISchema = Yup.object().shape({
  height: Yup.number()
    .required('Please enter the height value.')
    .min(1, 'The value is out of range (1-2.4m)')
    .max(2.4, 'The value is out of range (1-2.4m)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  weight: Yup.number()
    .required('Please enter the weight value.')
    .min(25, 'The value is out of range (25-200kg)')
    .max(200, 'The value is out of range (25-200kg)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testWaistSchema = Yup.object().shape({
  ratioWaist: Yup.number()
    .required('Please enter the ratio waist value.')
    .min(50, 'The value is out of range (50-160cm)')
    .max(160, 'The value is out of range (50-160cm)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  ratioHip: Yup.number()
    .required('Please enter the ratio hip value.')
    .min(50, 'The value is out of range (50-160cm)')
    .max(160, 'The value is out of range (50-160cm)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testBloodSchema = Yup.object().shape({
  systolic: Yup.number()
    .required('Please enter the systolic value.')
    .min(30, 'The value is out of range (30-280mmHg)')
    .max(280, 'The value is out of range (30-280mmHg)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  diastolic: Yup.number()
    .required('Please enter the diastolic value.')
    .min(30, 'The value is out of range (30-280mmHg)')
    .max(280, 'The value is out of range (30-280mmHg)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testFatAnalysisSchema = Yup.object().shape({
  testValue: Yup.number()
    .required('Please enter the value.')
    .min(4, 'The value is out of range (4-60)')
    .max(60, 'The value is out of range (4-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});
const testAcuitySchema = Yup.object().shape({
  leftAided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 0 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  rightAided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 0 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  leftUnaided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 1 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  rightUnaided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 1 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testAcuitySchemaForScreeningProgress = Yup.object().shape({
  checkedType: Yup.number().required('Please enter the test value.').nullable(),
  leftAided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 0 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  rightAided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 0 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  leftUnaided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 1 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  rightUnaided: Yup.number()
    .test('', 'Please enter the value.', function (value) {
      const checkedType = this.resolve(Yup.ref('checkedType'));
      return !(checkedType === 1 && !value);
    })
    .min(5, 'The value is out of range (5-60)')
    .max(60, 'The value is out of range (5-60)')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testUrineDipstickSchema = Yup.object().shape({
  urineDipstickBlood: Yup.string()
    .nullable()
    .required('Please enter the test value.'),
  urineDipstickGlucose: Yup.string()
    .nullable()
    .required('Please enter the test value.'),
  urineDipstickProtein: Yup.string()
    .nullable()
    .required('Please enter the test value.'),
});

const testPublicReflexSchema = Yup.object().shape({
  left: Yup.string().nullable().required('Please enter the test value.'),
  right: Yup.string().nullable().required('Please enter the test value.'),
});

const testTonometrySchema = Yup.object().shape({
  left: Yup.number()
    .required('Please enter the left eye IOP value.')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
  right: Yup.number()
    .required('Please enter the right eye IOP value.')
    .nullable()
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const testValueSchema = Yup.object().shape({
  testValue: Yup.string().nullable().required('Please enter the test value.'),
});

const priceSchema = Yup.object().shape({
  price: Yup.number()
    .max(999999999, 'The price is too high. Please enter again')
    .nullable()
    .transform((_, val) => (Number(val) ? Number(val) : null)),
});

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      'Password must be 8-32 characters long and includes at least one number, one letter (uppercase or lowercase), and may contain special characters like !@#$%^&*(),_-+='
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirm password does not match')
    .required('Confirm password is required'),
});

const dateScreeningSchema = Yup.object().shape({
  startDate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .required('Start Time is required')
    .test(
      '',
      'Start Time of screening date is not valid. Please check data from project again.',
      function (value) {
        const minDate = this.resolve(Yup.ref('minDate'));
        const maxDate = this.resolve(Yup.ref('maxDate'));

        const startTimeValue = moment(minDate).startOf('date').unix();
        const endTimeValue = moment(maxDate).startOf('date').unix();
        const startDateCompare = moment(value).startOf('date').unix();

        if (
          startTimeValue > startDateCompare ||
          endTimeValue < startDateCompare
        )
          return false;
        return true;
      }
    ),
  endDate: Yup.date()
    .nullable()
    .typeError('Invalid Date')
    .required('End Time is required')
    .test(
      '',
      'End Time of screening date is not valid. Please check data from project again.',
      function (value) {
        const minDate = this.resolve(Yup.ref('minDate'));
        const maxDate = this.resolve(Yup.ref('maxDate'));
        const startTimeValue = moment(minDate).startOf('date').unix();
        const endTimeValue = moment(maxDate).startOf('date').unix();
        const endDateCompare = moment(value).startOf('date').unix();
        if (endTimeValue < endDateCompare || startTimeValue > endDateCompare)
          return false;
        return true;
      }
    ),
});

const labNumberSchema = Yup.object().shape({
  labNumber: Yup.string()
    .min(7, 'Lab number must be at least 7 characters')
    .required('Lab number is required'),
});

const labNumberSchemaByProvider = Yup.object().shape({
  labProvider: Yup.string().nullable().required(),
  labNumber: Yup.string().when('labProvider', (labProvider, schema) => {
    let requiredLength = 0;

    if (labProvider === LAB_PROVIDERS.Eurofins) {
      requiredLength = 9;
    }

    if (labProvider === LAB_PROVIDERS.Innoquest) {
      requiredLength = 7;
    }

    if (
      labProvider === LAB_PROVIDERS.Eurofins ||
      labProvider === LAB_PROVIDERS.Innoquest
    ) {
      return schema.test({
        name: 'is-valid',
        test: function (value) {
          if (!value) {
            return this.createError({ message: 'Required.' });
          } else if (value.length !== requiredLength) {
            return this.createError({
              message: `Must have exact ${requiredLength} characters.`,
            });
          } else {
            /**
             *  With Eurofins provider
             *  format [Year prefix]M[6 digits running number] e.g. 24M000001
             */
            if (labProvider === LAB_PROVIDERS.Eurofins) {
              // Check year is a number with 2 digits and value has character A-Z in position based on format
              const regex = /^\d{2}[A-Z]\d{6}$/;
              return regex.test(value);
            }
            /**
             *  With Innoquest provider
             *  format [7 digits running number] e.g. 0000001
             */
            if (labProvider === LAB_PROVIDERS.Innoquest) {
              const regex = /^\d{7}$/;
              return regex.test(value);
            }
            return true;
          }
        },
      });
    }
  }),
});

const qualificationSchema = Yup.object().shape({
  degree: Yup.string().required('Degree is required'),
  school: Yup.string().required('School name is required'),
  startDate: Yup.date().nullable().required('Start date is required'),
});

const counsellorSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // mobile: Yup.string()
  //   .required('Phone number is required')
  //   .matches(PHONE_REGEX, 'Phone number is invalid')
  //   .test('testMobile', 'Phone number is invalid', function (value) {
  //     const countryCode = this.resolve(Yup.ref('countryCode'));
  //     return validateMobile(value, countryCode);
  //   }),
});

const counsellorEXSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // mobile: Yup.string()
  //   .required('Phone number is required')
  //   .matches(PHONE_REGEX, 'Phone number is invalid')
  //   .test('testMobile', 'Phone number is invalid', function (value) {
  //     const countryCode = this.resolve(Yup.ref('countryCode'));
  //     return validateMobile(value, countryCode);
  //   }),
});

const counsellingPaymentSchema = Yup.object().shape({
  counsellingType: Yup.string().required('Type is required'),
  duration: Yup.string().required('Duration is required'),
});

const appointmentCounsellingSchema = Yup.object().shape({
  appointmentDate: Yup.string().required('Appointment Date is required'),
  appointmentTime: Yup.string()
    .nullable()
    .required('Appointment Time is required')
    .test(
      'Must be greater than current time',
      'Appointment time must be later than current time',
      (value) =>
        value ? new Date(value).getTime() > new Date().getTime() : true
    ),
  // doctorId: Yup.string()
  //   .nullable()
  //   .test(
  //     'Must be not empty when assign doctor',
  //     'Doctor is required',
  //     function(value) {
  //       let typeModal = this.resolve(Yup.ref('typeModal'));
  //       if (['AssignDoctor', 'Create'].includes(typeModal) && isEmpty(value)) {
  //         return false;
  //       }
  //       return true;
  //     }
  //   ),
  patient: Yup.object()
    .test(
      'Must be a non-empty object when creating',
      'Patient is required',
      function (value) {
        let typeModal = this.resolve(Yup.ref('typeModal'));
        if (typeModal === 'Create' && isEmpty(value)) {
          return false;
        }
        return true;
      }
    )
    .nullable(),
});

const appointmentClinicSchema = Yup.object().shape({
  appointmentTime: Yup.string()
    .nullable()
    .required('Appointment Time is required')
    .test(
      'Must be greater than current time',
      'Time slot must be later than current time',
      (value) =>
        value ? new Date(value).getTime() > new Date().getTime() : true
    ),
});

export const schema = {
  serviceSchema,
  companySchema,
  locationSchema,
  healthProfileSchema,
  registerAccount,
  registerPersonal,
  loginSchema,
  studioSchema,
  trainerSchema,
  userSchema,
  userSchemaEdit,
  promotionSchema,
  categorySchema,
  appointmentSchema,
  mhsSchema,
  adminSchema,
  bannerSchema,
  validationEditBooking,
  validationEditBookingItem,
  basicModalForm,
  projectSchema,
  packageSchema,
  consultListPaymentStatus,
  statementSchema,
  stationSchema,
  personalInformationSchema,
  staffTypeSchema,
  projectCategorySchema,
  timeSlotTemplate,
  consentSchema,
  assignConsentForm,
  surveySchema,
  forgotPasswordSchema,
  motherStatementSchema,
  questionnairesSchema,
  testBMISchema,
  testWaistSchema,
  testBloodSchema,
  testFatAnalysisSchema,
  testAcuitySchema,
  testAcuitySchemaForScreeningProgress,
  testUrineDipstickSchema,
  priceSchema,
  resetPasswordSchema,
  dateScreeningSchema,
  labNumberSchema,
  adminEditSchema,
  testValueSchema,
  testPublicReflexSchema,
  testTonometrySchema,
  labNumberSchemaByProvider,
  qualificationSchema,
  counsellorSchema,
  counsellorEXSchema,
  counsellingPaymentSchema,
  appointmentCounsellingSchema,
  appointmentClinicSchema,
};

const validateData = (validateChoose, formValue, callback) => {
  return new Promise((resolve, reject) => {
    schema[validateChoose]
      .validate(formValue, { abortEarly: false })
      .then(() => {
        callback && callback(formValue);
        resolve();
      })
      .catch((err) => {
        console.log('err: ', err);
        const listError = get(err, 'inner');
        let errors = {};
        forEach(listError, (error) => {
          set(errors, error.path, get(error, 'errors[0]'));
        });
        reject(errors);
      });
  });
};

export default validateData;
