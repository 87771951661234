export const ROLES_MANAGEMENT_TABS = [
  {
    key: 'all-users',
    label: 'All User',
    items: [
      {
        privilege: 'HomescreenAllUsers',
        title: 'All Users',
        items: [],
      },
    ],
  },
  {
    key: 'teleconsult',
    label: 'Teleconsult',
    items: [
      { privilege: 'ConsultRoom', title: 'Queue', items: [] },
      {
        privilege: 'Appointments',
        title: 'Appointments',
        items: [],
      },
      { privilege: 'VisitRecords', title: 'All teleconsults (CE)', items: [] },
      { privilege: 'ConsultList', title: 'All teleconsults (Dr)', items: [] },
      {
        privilege: 'PatientManagement',
        title: 'Patient Details',
        items: [],
      },
      {
        privilege: 'TeleconsultSetup',
        title: 'Setup',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'AppointmentConfig',
            title: 'Appointments',
            items: [],
          },

          {
            privilege: 'DoctorManagement',
            title: 'Doctors',
            items: [],
          },
          { privilege: 'CAManagement', title: 'CE', items: [] },
          { privilege: 'PromoCode', title: 'Promo Code', items: [] },
        ],
      },
      {
        privilege: 'TeleconsultAnalytics',
        title: 'Analytics',
        items: [],
      },

      // { privilege: 'MHSManagement', title: 'MHS Management', items: [] },
    ],
  },
  {
    key: 'clinic',
    label: 'Clinic',
    items: [
      { privilege: 'QueueRequest', title: 'Queue Requests', items: [] },
      {
        privilege: 'ClinicAppointments',
        title: 'Appointments',
        items: [],
      },
      {
        privilege: 'ClinicSetUpMenu',
        title: 'Setup',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'ClinicSetUp',
            title: 'Locations',
            items: [],
          },
          {
            privilege: 'ClinicServices',
            title: 'Services',
            items: [],
          },
        ],
      },
    ],
  },

  {
    key: 'health-screening',
    label: 'Health Screening',
    items: [
      {
        privilege: 'Upcoming',
        title: 'Appointments Today',
        items: [],
      },
      {
        privilege: 'HealthScreeningAppointments',
        title: 'All screenings',
        items: [],
      },
      {
        privilege: 'OnsiteOps',
        title: 'Onsite Ops',
        items: [],
        isHidden: false,
        child: [
          {
            privilege: 'EOrder',
            title: 'Lab E-order',
            items: [],
          },
          {
            title: 'Closing Report',
            privilege: 'ClosingReport',
            items: [],
          },
        ],
      },
      {
        title: 'Backroom',
        privilege: 'Backroom',
        items: [],
      },
      {
        title: 'Corporate Reports',
        privilege: 'CorporateReport',
        items: [],
      },

      {
        privilege: 'Setup',
        title: 'Setup',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'CompanyManagement',
            title: 'Company',
            items: [],
          },
          {
            privilege: 'SiteConfig',
            title: 'Locations',
            items: [],
          },

          {
            privilege: 'Packages',
            title: 'Screening Packages',
            items: [],
          },
          {
            privilege: 'Profiles',
            title: 'Test Profiles',
            items: [],
          },
          {
            privilege: 'OtherServices',
            title: 'Other Services',
            items: [],
          },

          {
            title: 'Lifestyle Survey',
            privilege: 'Survey',
            items: [],
          },
          {
            title: 'Consent Form',
            privilege: 'ConsentForm',
            items: [],
          },

          {
            privilege: 'ImportHSAppointments',
            title: 'Import HS Appointments',
            items: [],
          },
          {
            title: 'Stations',
            privilege: 'StationManagement',
            items: [],
          },
        ],
      },
      {
        privilege: 'HealthScreeningAdmin',
        title: 'Admin',
        items: [],
        isOpen: true,
        child: [
          {
            title: 'Timeslot Template',
            privilege: 'TimeslotsTemplate',
            items: [],
          },
          {
            title: 'Motherhood Statement',
            privilege: 'MotherhoodStatements',
            items: [],
          },
        ],
      },
    ],
  },
  {
    key: 'telecounsel',
    label: 'Counselling',
    items: [
      {
        privilege: 'CounselQueue',
        title: 'Queue',
        items: [],
      },

      {
        privilege: 'CounselAppointments',
        title: 'Appointments',
        items: [],
      },
      {
        privilege: 'CounselAll',
        title: 'All counsellings',
        items: [],
      },
      {
        privilege: 'ResourcesManagement',
        title: 'Setup',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'CounsellorConfig',
            title: 'Appointments',
            items: [],
          },
          {
            privilege: 'CounsellorManagement',
            title: 'Counsellors',
            items: [],
          },
          {
            privilege: 'CounsellorExecutivesManagement',
            title: 'CE',
            items: [],
          },
        ],
      },
    ],
  },

  // {
  //   key: 'telefit',
  //   label: 'Telefit',
  //   items: [
  //     {
  //       privilege: 'Workout',
  //       title: 'Workout',
  //       isOpen: true,
  //       child: [
  //         {
  //           privilege: 'Categories',
  //           title: 'Categories',
  //           items: [],
  //         },
  //         {
  //           privilege: 'Livestream',
  //           title: 'Livestream',
  //           items: [],
  //         },
  //         {
  //           privilege: 'OnDemand',
  //           title: 'On Demand',
  //           items: [],
  //         },
  //         {
  //           privilege: 'StudioClasses',
  //           title: 'Studio Classes',
  //           items: [],
  //         },
  //       ],
  //     },
  //     {
  //       privilege: 'UpcomingWorkout',
  //       title: 'Upcoming Workout',
  //       items: [],
  //     },
  //     {
  //       privilege: 'StudioManagement',
  //       title: 'Studio Management',
  //       items: [],
  //     },
  //     {
  //       privilege: 'TrainerManagement',
  //       title: 'Trainer Management',
  //       items: [],
  //     },
  //     {
  //       privilege: 'InternalTrainerManagement',
  //       title: 'Internal Trainer Management',
  //       items: [],
  //     },
  //   ],
  // },
  {
    key: 'minc-admin',
    label: 'Support',
    items: [
      {
        privilege: 'Admin',
        title: 'Admin',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'UserManagement',
            title: 'Users',
            items: [],
          },

          {
            privilege: 'RoleManagement',
            title: 'Roles',
            items: [],
          },
        ],
      },
      {
        privilege: 'CustomerCareSupport',
        title: 'Customer Care Support',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'StuckAccounts',
            title: 'Restricted Accounts',
            items: [],
          },
        ],
      },

      {
        privilege: 'EcommerceManagement',
        title: 'E-commerce',
        items: [],
      },
      {
        privilege: 'Marketing',
        title: 'Marketing',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'BannerManagement',
            title: 'Banners',
            items: [],
          },

          {
            privilege: 'MarketingMessage',
            title: 'App Notifications',
            items: [],
          },
        ],
      },

      // {
      //   privilege: 'DeletionWorklist',
      //   title: 'Deletion Worklist',
      //   items: [],
      // },

      // { privilege: 'LabResult', title: 'Lab Result', items: [] },
    ],
  },
];

export const renderTablePermission = () => {
  const tableDefault = ROLES_MANAGEMENT_TABS.map((table) => ({
    ...table,
    items: [],
  }));

  return tableDefault;
};

export const ROLE_TYPE = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'HsAdmin', label: 'HS Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
  { roleType: 'ClinicAdmin', label: 'Clinic Admin' },

  // { roleType: 'Swabber', label: 'Swabber' },
  { roleType: 'MMSPublicist', label: 'MMS Publicist' },
  { roleType: 'MMSProjectManager', label: 'MMS Project Manager' },
  { roleType: 'MSCManager', label: 'MSC Manager' },
  { roleType: 'MSCCSA', label: 'MSC CSA' },
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },

  { roleType: 'BackroomStaff', label: 'Backroom Staff' },
  { roleType: 'ScreeningDoctor', label: 'Screening Doctor' },
  { roleType: 'DoctorAdmin', label: 'Doctor Admin' },
  { roleType: 'Nurse', label: 'Nurse' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'CSOManager', label: 'CSO Manager' },

  { roleType: 'SaleManager', label: 'Sales Manager' },
  { roleType: 'SaleExec', label: 'Sales Exec' },
  { roleType: 'FinanceManager', label: 'Finance Manager' },
  { roleType: 'FinanceExec', label: 'Finance Exec' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },

  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'InternalTrainer', label: 'Telefit Internal Trainer' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },

  { roleType: 'MHS', label: 'MHS' },
  { roleType: 'LabAdmin', label: 'MHS Support' },
  { roleType: 'MarketingManager', label: 'Marketing Manager' },
  { roleType: 'Counsellor', label: 'Counsellor' },
  { roleType: 'CounsellorExecutive', label: 'Counsellor Executive' },
];

export const ROLE_TYPE_CLINIC_ADMIN = [
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
];
export const ROLE_TYPE_MMS_PROJECT_MANAGER = [
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },
];
export const ROLE_TYPE_CA = [{ roleType: 'CA', label: 'CA' }];

export const ROLE_MERGE_TRAINER = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'HsAdmin', label: 'HS Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
  { roleType: 'ClinicAdmin', label: 'Clinic Admin' },

  { roleType: 'Swabber', label: 'Swabber' },
  { roleType: 'MMSPublicist', label: 'MMS Publicist' },
  { roleType: 'MMSProjectManager', label: 'MMS Project Manager' },
  { roleType: 'MSCManager', label: 'MSC Manager' },
  { roleType: 'MSCCSA', label: 'MSC CSA' },

  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },

  { roleType: 'BackroomStaff', label: 'Backroom Staff' },
  { roleType: 'ScreeningDoctor', label: 'Screening Doctor' },
  { roleType: 'DoctorAdmin', label: 'Doctor Admin' },
  { roleType: 'Nurse', label: 'Nurse' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'CSOManager', label: 'CSO Manager' },

  { roleType: 'SaleManager', label: 'Sales Manager' },
  { roleType: 'SaleExec', label: 'Sales Exec' },
  { roleType: 'FinanceManager', label: 'Finance Manager' },
  { roleType: 'FinanceExec', label: 'Finance Exec' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },

  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },
  { roleType: 'MHS', label: 'MHS' },

  { roleType: 'LabAdmin', label: 'MHS Support' },
  { roleType: 'MarketingManager', label: 'Marketing Manager' },
  { roleType: 'Counsellor', label: 'Counsellor' },
  { roleType: 'CounsellorExecutive', label: 'Counsellor Executive' },
];
